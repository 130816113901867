import type { ReactElement } from 'react'
import { Fragment, useEffect, useState } from 'react'

import { useFragment, graphql } from 'react-relay'
import { styled } from 'styled-components'

import type { DetailedPreviewCardProps } from '../../DetailedPreviewCard/DetailedPreviewCard'

import type { authorization_authorization$key } from '__generated__/authorization_authorization.graphql'
import type { authorization_content$key } from '__generated__/authorization_content.graphql'
import type { ContentPieceCardWrapper_contentPiece$key } from '__generated__/ContentPieceCardWrapper_contentPiece.graphql'
import ContentPieceBookmarkIcon from 'components/BookmarkIcon/Relay/ContentPieceBookmarkIcon'
import ServerImage from 'components/Image/Relay/ServerImage'
import Tag from 'components/Tag/Relay/Tag'
import { useHasContentAccess } from 'lib/authorization/useHasContentAccess'
import { formatShortDate } from 'lib/formatShortDate'
import { useContentUrl } from 'modules/ContentDisplay/utils'
import type { ColorName, ThemedComponent } from 'theme'
import { fonts } from 'theme'
import getImageObjectPositionBasedOnContentType from 'utils/contentTypeImageLogic/getImageObjectPositionBasedOnContentType'

type ContentPieceCardWrapperProps = {
    // Fragment key for content piece data
    contentPiece: ContentPieceCardWrapper_contentPiece$key
    // Fragment key for user authorization data
    authorization: authorization_authorization$key
    // Fragment key for content authorization data
    contentAuthorization: authorization_content$key
    CardComponent: React.FC<DetailedPreviewCardProps>
    priorityImageLoad?: boolean
    onClick?: () => void
    color?: ColorName
}

const ContentPieceCardWrapper = (props: ContentPieceCardWrapperProps): ReactElement | null => {
    const contentPiece = useFragment(ContentPreviewCardFragment, props.contentPiece)
    const { CardComponent, color } = props

    const {
        headerImage,
        title,
        subtitle,
        dateUpdatedDisplay,
        mostRelevantTags,
        contentPieceType: { name: contentType, publicName: contentTypePublicName },
    } = contentPiece

    const { priorityImageLoad } = props

    const userHasAccess = useHasContentAccess({
        authorization: props.authorization,
        contentPieceAuthorization: contentPiece,
        contentAuthorization: props.contentAuthorization,
    })

    // This is to make sure we update the date client side to avoid hydration errors
    const [metaText, setMetaText] = useState<string | undefined>()
    useEffect(() => {
        setMetaText(formatShortDate(dateUpdatedDisplay))
    }, [dateUpdatedDisplay])

    const url = useContentUrl(contentPiece)
    if (!url) return null

    const tags = mostRelevantTags.edges.map((tag, index: number) => (
        <Fragment key={index}>
            <Tag tag={tag.node} fontSize={fonts.size.XXS} />
            {index < mostRelevantTags.edges.length - 1 && <Dot>•</Dot>}
        </Fragment>
    ))

    const image = (
        <ServerImage
            image={headerImage}
            alt={title}
            objectFit="cover"
            objectPosition={getImageObjectPositionBasedOnContentType(contentType)}
            priority={priorityImageLoad}
        />
    )

    const bookmarkButton = (
        <ContentPieceBookmarkIcon
            contentPiece={contentPiece}
            toggledVia="Preview Card"
            variant="preview-card"
        />
    )

    const authorName = contentPiece.author?.byline
    const author = authorName
        ? {
              name: contentPiece.author?.byline,
              slug: contentPiece.author?.slug,
              isActive: contentPiece.author?.isActive,
          }
        : undefined

    return (
        <CardComponent
            url={url}
            image={image}
            title={title}
            subtitle={subtitle}
            metaText={metaText}
            author={author}
            tags={tags}
            isLocked={!userHasAccess}
            contentTypePublicName={contentTypePublicName}
            bookmarkButton={bookmarkButton}
            onClick={props.onClick}
            color={color}
        />
    )
}

const Dot = styled.span<ThemedComponent<{}>>`
    font-size: ${({ theme }) => theme.fontSize.XXS};
    color: ${({ theme }) => theme.palette.accent[500]};
    text-align: center;
`

const ContentPreviewCardFragment = graphql`
    fragment ContentPieceCardWrapper_contentPiece on ContentPieceInterface {
        id
        title
        subtitle
        slug
        headerImage {
            ...ServerImage_image
        }
        mostRelevantTags(first: 2) {
            edges {
                node {
                    ...Tag_tag
                }
            }
        }
        dateUpdatedDisplay
        author {
            id
            byline
            slug
            isActive
        }
        canonicalLink
        contentPieceType {
            name
            publicName
        }

        ...authorization_contentPiece
        ...ContentPieceBookmarkIcon_contentPiece
    }
`

export default ContentPieceCardWrapper
