import type { MouseEvent } from 'react'

import { Content, Portal, Root, Trigger } from '@radix-ui/react-popover'
import styled, { css } from 'styled-components'

import type { ThemedComponent } from 'theme/Types'

export type PopoverProps = {
    /**
     * The content to be wrapped with a popover
     */
    children: React.ReactNode
    /**
     * The message to be displayed in the popover
     */
    popoverMessage: string
    /**
     * The controlled open state of the popover.
     */
    isOpen?: boolean
    onClick?: (event: MouseEvent<HTMLElement>) => void
    /**
     * How long the popover should be displayed in seconds
     * @default 1
     * @optional
     * @type number
     */
    popoverTimeout?: PopoverContentProps['popoverTimeout']
}

const Popover = ({ children, popoverMessage, isOpen, onClick }: PopoverProps) => {
    return (
        <Root open={isOpen}>
            <PopoverTrigger onClick={onClick}>{children}</PopoverTrigger>
            <Portal>
                <PopoverContent sideOffset={5}>{popoverMessage}</PopoverContent>
            </Portal>
        </Root>
    )
}

const PopoverTrigger = styled(Trigger)`
    display: inline-flex;
    background-color: inherit;
    border: none;
    padding: 0;
    cursor: pointer;
`

type PopoverContentProps = {
    popoverTimeout?: number
}

const PopoverContent = styled(Content)<ThemedComponent<PopoverContentProps>>`
    ${({ theme }) =>
        css`
            border-radius: ${theme.radii.default};
            border: solid 1px ${theme.palette.neutral[0]};
            font-family: ${theme.fonts.body};
            font-size: ${theme.fontSize.XS};
            line-height: ${theme.lineHeight.paragraph};
            color: ${theme.palette.neutral[900]};
            background-color: ${theme.palette.neutral[0]};
            box-shadow: ${theme.elevation[2]};
        `}
    padding: 0.3rem 0.5rem;
    user-select: none;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;

    @keyframes fadeOut {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    animation: fadeOut 1.3s forwards;
    animation-delay: ${props => props.popoverTimeout || 1}s;
`

export default Popover
