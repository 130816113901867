/*
useHasContentAccess

Used to determine whether someone has full access to content or not.
To an extent this is coupled with the server since the server will
not expose the whole content piece unless its own auth conditions
are met.

TODO: This logic should be moved to the server.

*/

import { useFragment } from 'react-relay'

import { AuthorizationFragment, ContentPieceFragment, ContentFragment } from './authorization'
import { hasContentAccess } from './hasContentAccess'

import type { authorization_authorization$key } from '__generated__/authorization_authorization.graphql'
import type { authorization_content$key } from '__generated__/authorization_content.graphql'
import type { authorization_contentPiece$key } from '__generated__/authorization_contentPiece.graphql'

interface Props {
    authorization: authorization_authorization$key
    contentPieceAuthorization: authorization_contentPiece$key
    contentAuthorization: authorization_content$key
}

export const useHasContentAccess = ({
    authorization,
    contentPieceAuthorization,
    contentAuthorization,
}: Props) => {
    const auth = useFragment(AuthorizationFragment, authorization)
    const contentPieceAuth = useFragment(ContentPieceFragment, contentPieceAuthorization)
    const contentAuth = useFragment(ContentFragment, contentAuthorization)

    const {
        roles: { hasPremiumSubscription, isPreviewingContentAllowance, isPreviewing, isFreemium },
    } = auth

    const {
        isPremium,
        contentPieceType: { isAvailableForFreemium },
    } = contentPieceAuth

    const { anonymousUserHasPremium } = contentAuth

    return hasContentAccess({
        hasPremiumSubscription,
        isPreviewingContentAllowance,
        anonymousUserHasPremium,
        isPreviewing,
        isPremium,
        isFreemium,
        isAvailableForFreemium,
    })
}
