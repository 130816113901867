/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ServerImage_image = {
    readonly full: string;
    readonly " $refType": "ServerImage_image";
};
export type ServerImage_image$data = ServerImage_image;
export type ServerImage_image$key = {
    readonly " $data"?: ServerImage_image$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ServerImage_image">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ServerImage_image",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "full",
      "storageKey": null
    }
  ],
  "type": "URLImage",
  "abstractKey": null
};
(node as any).hash = 'decf73f2239c3f534ea352be74babd6d';
export default node;
