import { graphql } from 'react-relay'
import type { Environment } from 'relay-runtime'

import type { toggleBookmarkMutationResponse } from '__generated__/toggleBookmarkMutation.graphql'
import createMutationPromise from 'lib/createMutationPromise'

type InputObject = {
    contentPieceId: string
    isBookmarked: boolean
}

/**
 * We haven't added the bookmarks page fragment in here to be updated as we want
 * the content piece to stay on the bookmarks page after it's been un-bookmarked in case it was
 * accidental (so can then be re-bookmarked by the user).
 * Adding the fragment here also causes issues with the pagination cursor on the bookmarks page,
 * so if we wanted to change this behaviour in the future (and immediately remove the un-bookmarked piece),
 * there'd need to be some additional investigation.
 * We'd need to figure out how to return the correct value for the bookmarks content piece query, as returning an arbitrary count of x (like we do in the app)
 * means the value in the store gets replace by the first x items. This leads to weirdness in the UI for the bookmarks page, as if you un-bookmark
 * the (x+1)th item, the page will re-render with only the x items before and then trigger the pagination query to get to the place in the list where the (x+1)th item was removed from.
 * */
const toggleBookmarkMutation = (relayEnv: Environment) => (input: InputObject) => {
    return createMutationPromise<toggleBookmarkMutationResponse>(relayEnv)({
        mutation: graphql`
            mutation toggleBookmarkMutation($input: ToggleBookmarkInput!) {
                toggleBookmark(input: $input) {
                    contentPiece {
                        id
                        isBookmarked
                    }
                }
            }
        `,
        // For some reason the "optimisticResponse" prop didn't seem to have
        // the desired effect. But might be worth playing with if our needs grow
        // and the below gets complicated
        optimisticUpdater: store => {
            const node = store.get(input.contentPieceId)

            if (node) {
                node.setValue(!input.isBookmarked, 'isBookmarked')
            }
        },
        variables: { input },
    })
}

export default toggleBookmarkMutation
