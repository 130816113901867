import React from 'react'

import { graphql, useFragment } from 'react-relay'

import type { ImageProps } from '../Image'

import type { ServerImage_image$key } from '__generated__/ServerImage_image.graphql'
import Image from 'components/Image'
import type { Omit } from 'types'

/*
    DEPRECATED. Use AccessibleImage instead.

    To be used whenever we're using a responsive imgix (URLImage)
    from the server. Takes the appropriate Relay props and passes
    them down to the imgix web wrapper.

    As we grow this we should decide whether we want to consolidate
    any styles/config with the generic Image component.

*/

export type WrappedImageProps = Omit<ImageProps, 'src'>

type SizeDefinition =
    | {
          sizes?: string // See https://ericportis.com/posts/2014/srcSet-sizes/
      }
    | { height?: number; width: number }

type LocalServerImageProps = {
    image: ServerImage_image$key
    alt: string
} & SizeDefinition &
    Omit<ImageProps, 'src'>

export type ServerImageProps = LocalServerImageProps

const ServerImage = ({ image, alt, ...props }: ServerImageProps) => {
    const { full } = useFragment(ServerImageFragment, image)

    return <Image src={full} alt={alt} {...props} />
}

const ServerImageFragment = graphql`
    fragment ServerImage_image on URLImage {
        full
    }
`

export default ServerImage
