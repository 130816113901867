/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ContentPieceBookmarkIcon_contentPiece = {
    readonly id: string;
    readonly isBookmarked: boolean;
    readonly title: string;
    readonly " $refType": "ContentPieceBookmarkIcon_contentPiece";
};
export type ContentPieceBookmarkIcon_contentPiece$data = ContentPieceBookmarkIcon_contentPiece;
export type ContentPieceBookmarkIcon_contentPiece$key = {
    readonly " $data"?: ContentPieceBookmarkIcon_contentPiece$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ContentPieceBookmarkIcon_contentPiece">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentPieceBookmarkIcon_contentPiece",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isBookmarked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "ContentPiece",
  "abstractKey": null
};
(node as any).hash = 'd48bb69ce572dc2f048e0b6c5df64b99';
export default node;
