/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ContentPieceCardWrapper_contentPiece = {
    readonly id: string;
    readonly title: string;
    readonly subtitle: string;
    readonly slug: string;
    readonly headerImage: {
        readonly " $fragmentRefs": FragmentRefs<"ServerImage_image">;
    };
    readonly mostRelevantTags: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly " $fragmentRefs": FragmentRefs<"Tag_tag">;
            };
        }>;
    };
    readonly dateUpdatedDisplay: string;
    readonly author: {
        readonly id: string;
        readonly byline: string;
        readonly slug: string;
        readonly isActive: boolean;
    } | null;
    readonly canonicalLink: string;
    readonly contentPieceType: {
        readonly name: string;
        readonly publicName: string;
    };
    readonly " $fragmentRefs": FragmentRefs<"authorization_contentPiece" | "ContentPieceBookmarkIcon_contentPiece">;
    readonly " $refType": "ContentPieceCardWrapper_contentPiece";
};
export type ContentPieceCardWrapper_contentPiece$data = ContentPieceCardWrapper_contentPiece;
export type ContentPieceCardWrapper_contentPiece$key = {
    readonly " $data"?: ContentPieceCardWrapper_contentPiece$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ContentPieceCardWrapper_contentPiece">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentPieceCardWrapper_contentPiece",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subtitle",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "URLImage",
      "kind": "LinkedField",
      "name": "headerImage",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ServerImage_image"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 2
        }
      ],
      "concreteType": "GenericTagConnection",
      "kind": "LinkedField",
      "name": "mostRelevantTags",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GenericTagNonNullEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "Tag_tag"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "mostRelevantTags(first:2)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateUpdatedDisplay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Author",
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "byline",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isActive",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canonicalLink",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentPieceType",
      "kind": "LinkedField",
      "name": "contentPieceType",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publicName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "authorization_contentPiece"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentPieceBookmarkIcon_contentPiece"
    }
  ],
  "type": "ContentPieceInterface",
  "abstractKey": "__isContentPieceInterface"
};
})();
(node as any).hash = '6df7ae957c5ccfe7f5dc6dde88746b3c';
export default node;
