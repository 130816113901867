/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type Tag_tag = {
    readonly __typename: "AssetClassTag";
    readonly name: string;
    readonly slug: string;
    readonly " $refType": "Tag_tag";
} | {
    readonly __typename: "GeographyTag";
    readonly name: string;
    readonly slug: string;
    readonly " $refType": "Tag_tag";
} | {
    readonly __typename: "ContentTag";
    readonly name: string;
    readonly slug: string;
    readonly " $refType": "Tag_tag";
} | {
    /*This will never be '%other', but we need some
    value in case none of the concrete values match.*/
    readonly __typename: "%other";
    readonly " $refType": "Tag_tag";
};
export type Tag_tag$data = Tag_tag;
export type Tag_tag$key = {
    readonly " $data"?: Tag_tag$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"Tag_tag">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "slug",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Tag_tag",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "AssetClassTag",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "GeographyTag",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "ContentTag",
      "abstractKey": null
    }
  ],
  "type": "GenericTag",
  "abstractKey": "__isGenericTag"
};
})();
(node as any).hash = 'f98848e6c81aab66993fef4d5c718150';
export default node;
