import type { MouseEvent } from 'react'
import { useState } from 'react'

import { useDialogState } from 'reakit/Dialog'
import styled from 'styled-components'

import type { IconStyleProps } from 'components/Icon'
import Icon from 'components/Icon'
import NotLoggedInModal from 'components/NotLoggedInModal'
import Pill from 'components/Pill'
import Popover from 'components/Popover'
import theme from 'theme'

export type BookmarkIconVariant = 'content-piece' | 'preview-card'

type Props = {
    isBookmarked: boolean
    // Different styling is needed for the bookmark icon depending on where it is used
    variant: BookmarkIconVariant
    handleBookmark: () => void
} & IconStyleProps

const BookmarkIcon = ({ isBookmarked, handleBookmark, variant, ...styleProps }: Props) => {
    const loginDialog = useDialogState()
    const [tooltipVisible, setTooltipVisible] = useState(false)

    const onCloseLoginDialog = (event: MouseEvent<HTMLElement>) => {
        // We want to prevent navigating to the content piece if we
        // click on the bookmark icon on a preview card
        // (as the whole card is wrapped in a Link)
        if (variant === 'preview-card') {
            event.preventDefault()
        }
        loginDialog.setVisible(false)
    }

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        // We want to prevent navigating to the content piece if we
        // click on the bookmark icon on a preview card
        // (as the whole card is wrapped in a Link)
        if (variant === 'preview-card') {
            event.preventDefault()
        }

        // When the user is not logged in they cannot bookmark any content,
        // so we show a dialog to prompt them to log in (and do not fire the mutation or tracking).
        if (isBookmarked === undefined) {
            loginDialog.setVisible(true)
            return
        }

        // Trigger the parent function to bookmark the content
        handleBookmark()
        if (!isBookmarked) setTooltipVisible(previousState => !previousState)
        if (isBookmarked) setTooltipVisible(false)
    }

    return (
        <>
            <Popover popoverMessage="Bookmarked!" isOpen={tooltipVisible} onClick={handleClick}>
                {variant === 'content-piece' && (
                    <Icon
                        data-cy="bookmark-icon"
                        icon={isBookmarked ? 'RiBookmarkFill' : 'RiBookmarkLine'}
                        aria-label={isBookmarked ? 'Remove bookmark' : 'Add bookmark'}
                        borderRadius={theme.radii.rounded}
                        margin="0.5rem"
                        {...styleProps}
                    />
                )}
                {variant === 'preview-card' && (
                    <StyledPill
                        icon={isBookmarked ? 'RiBookmarkFill' : 'RiBookmarkLine'}
                        iconSize="1rem"
                        variant="rounded-rectangle"
                        aria-label={isBookmarked ? 'Remove bookmark' : 'Add bookmark'}
                    ></StyledPill>
                )}
            </Popover>
            <NotLoggedInModal
                title="Create A Finimize Premium Account To Bookmark Content + More"
                subtitle="Finimize Premium unlocks advanced research and daily insights from our team of expert analysts"
                baseId={loginDialog.baseId}
                visible={loginDialog.visible}
                onClose={onCloseLoginDialog}
                trackingParams={{ location: 'Bookmark icon' }}
            />
        </>
    )
}

const StyledPill = styled(Pill)`
    &:hover {
        background: ${theme.palette.info[100]};
    }
`

export default BookmarkIcon
