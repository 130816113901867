/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ToggleBookmarkInput = {
    contentPieceId: string;
    isBookmarked: boolean;
    clientMutationId?: string | null | undefined;
};
export type toggleBookmarkMutationVariables = {
    input: ToggleBookmarkInput;
};
export type toggleBookmarkMutationResponse = {
    readonly toggleBookmark: {
        readonly contentPiece: {
            readonly id: string;
            readonly isBookmarked: boolean;
        } | null;
    } | null;
};
export type toggleBookmarkMutation = {
    readonly response: toggleBookmarkMutationResponse;
    readonly variables: toggleBookmarkMutationVariables;
};



/*
mutation toggleBookmarkMutation(
  $input: ToggleBookmarkInput!
) {
  toggleBookmark(input: $input) {
    contentPiece {
      __typename
      id
      isBookmarked
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isBookmarked",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "toggleBookmarkMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ToggleBookmarkPayload",
        "kind": "LinkedField",
        "name": "toggleBookmark",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "contentPiece",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "toggleBookmarkMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ToggleBookmarkPayload",
        "kind": "LinkedField",
        "name": "toggleBookmark",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "contentPiece",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f8b0d2c50e4eefa2a9f22bb1e907868f",
    "id": null,
    "metadata": {},
    "name": "toggleBookmarkMutation",
    "operationKind": "mutation",
    "text": "mutation toggleBookmarkMutation(\n  $input: ToggleBookmarkInput!\n) {\n  toggleBookmark(input: $input) {\n    contentPiece {\n      __typename\n      id\n      isBookmarked\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '3addaa1c9b006237c3973492f82de348';
export default node;
