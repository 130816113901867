import React from 'react'
import { useCallback } from 'react'

import debounce from 'lodash/debounce'
import { graphql, useFragment, useRelayEnvironment } from 'react-relay'
import type { Environment } from 'relay-runtime'

import type { BookmarkIconVariant } from '../BookmarkIcon'
import BookmarkIcon from '../BookmarkIcon'

import type { ContentPieceBookmarkIcon_contentPiece$key } from '__generated__/ContentPieceBookmarkIcon_contentPiece.graphql'
import type { IconStyleProps } from 'components/Icon'
import { trackToggledBookmarkOn, trackToggledBookmarkOff } from 'lib/analyticsApi'
import toggleBookmark from 'relay/mutations/toggleBookmark'

type RelayProps = {
    contentPiece: ContentPieceBookmarkIcon_contentPiece$key
    // String to add to tracking to indicate where the toggle was initiated from
    toggledVia: string
    variant: BookmarkIconVariant
}

type Props = RelayProps & IconStyleProps

const TOGGLE_DEBOUNCE = 250

/*
    Wrap tracking and mutation logic into one function so it can be debounced
*/
const toggleBookmarkAndTrack = debounce(
    async ({
        contentPieceId,
        isBookmarked,
        title,
        toggledVia,
        environment,
    }: {
        contentPieceId: string
        isBookmarked: boolean
        environment: Environment
        title: string
        toggledVia: string
    }) => {
        await toggleBookmark(environment)({
            contentPieceId,
            isBookmarked,
        })

        if (isBookmarked) {
            trackToggledBookmarkOff({ contentPieceId, contentPieceName: title, toggledVia })
        } else {
            trackToggledBookmarkOn({ contentPieceId, contentPieceName: title, toggledVia })
        }
    },
    TOGGLE_DEBOUNCE,
    { leading: true }, // Important otherwise user feedback doesn't feel immediate
)

const ContentPieceBookmarkIcon = ({
    toggledVia,
    contentPiece,
    variant,
    ...styleProps
}: Props): React.ReactElement => {
    const { id, isBookmarked, title } = useFragment(ContentPieceBookmarkIconFragment, contentPiece)

    const environment = useRelayEnvironment()

    const handleBookmark = useCallback(async () => {
        // TODO: Show tooltip when bookmarked
        await toggleBookmarkAndTrack({
            contentPieceId: id,
            isBookmarked,
            title,
            toggledVia,
            environment,
        })
    }, [isBookmarked, id, title, toggledVia, environment])

    return (
        <BookmarkIcon
            isBookmarked={isBookmarked}
            handleBookmark={handleBookmark}
            variant={variant}
            {...styleProps}
        />
    )
}

const ContentPieceBookmarkIconFragment = graphql`
    fragment ContentPieceBookmarkIcon_contentPiece on ContentPiece {
        id
        isBookmarked
        title
    }
`

export default ContentPieceBookmarkIcon
