import { graphql, useFragment } from 'react-relay'

import TagUI from '../Tag'

import type { Tag_tag$key } from '__generated__/Tag_tag.graphql'
import type { ParagraphProps } from 'components/Paragraph'
import type { TagPlacementEnum } from 'lib/analyticsApi'

type TrackingParams = {
    placement: TagPlacementEnum
}

export type TagProps = {
    tag: Tag_tag$key
    fontSize: ParagraphProps['fontSize']
    trackingParams?: TrackingParams
}

/**
 * @param tag: the relay key for tag
 * @param trackingParams: tracking params for mixpanel
 * @param maxWidth: maximum width of the tag. If provided, will truncate the tag name
 * @param fontSize: font size of the tag
 * @returns a thin pill used to show tags
 */
const Tag = ({ tag: tagKey, trackingParams, fontSize }: TagProps) => {
    const tag = useFragment(TagFragment, tagKey)
    // the below line is because otherwise we get types issues due to the fact we're returning different types of tags
    if (tag.__typename === '%other') return null

    const { name, slug } = tag

    return <TagUI tag={{ name, slug }} trackingParams={trackingParams} fontSize={fontSize} />
}

export const TagFragment = graphql`
    fragment Tag_tag on GenericTag {
        __typename
        ... on AssetClassTag {
            name
            slug
        }
        ... on GeographyTag {
            name
            slug
        }
        ... on ContentTag {
            name
            slug
        }
    }
`

export default Tag
