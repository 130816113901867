import { useRouter } from 'next/router'
import type { DialogProps } from 'reakit/Dialog'
import styled from 'styled-components'

import Button from 'components/Button'
import Card from 'components/Card'
import Heading from 'components/Heading'
import Icon from 'components/Icon'
import { LegacyLink as Link } from 'components/Link'
import Modal from 'components/Modal'
import Paragraph from 'components/Paragraph'
import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'
import Set from 'components/Set'
import type { NotLoggedInModalConversionTrackingProps } from 'lib/analyticsApi'
import { trackNotLoggedInModalConversion } from 'lib/analyticsApi'
import { loginRedirectParam } from 'modules/SignUpOrLogin/Hooks/useLoginRedirect'
import { routeMap } from 'shared/routes'
import { fonts } from 'theme/index'

type Props = {
    title: string
    subtitle: string
    baseId: DialogProps['baseId']
    visible: DialogProps['visible']
    onClose: BoxProps['onClick']
    trackingParams: NotLoggedInModalConversionTrackingProps | null
}

const NotLoggedInModal = ({ title, subtitle, baseId, visible, onClose, trackingParams }: Props) => {
    const { asPath } = useRouter()
    const redirectParams = {
        [loginRedirectParam]: encodeURIComponent(asPath),
    }

    const trackClick = () => !!trackingParams && trackNotLoggedInModalConversion(trackingParams)

    return (
        <Modal
            baseId={baseId}
            visible={visible}
            ariaLabel="Log in"
            data-cy="bookmark-notLoggedInModal"
        >
            <Box marginX="auto" textAlign="center" marginTop={5} paddingX={1}>
                <ModalContent>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Box pb={1} marginLeft="auto" onClick={onClose}>
                            <CloseIcon size="default" icon="RiCloseLine" title="close" />
                        </Box>
                        <Content spacing={1}>
                            <Heading fontSize={fonts.size.L}>{title}</Heading>
                            <Paragraph as="h2">{subtitle}</Paragraph>
                            <Button
                                data-cy="free-trial-button"
                                url={{
                                    pathname: routeMap.checkout(),
                                    query: {
                                        'ref-page': asPath,
                                    },
                                }}
                                onClick={trackClick}
                            >
                                Try free
                            </Button>

                            {/* Don't show this if already logged in */}
                            <InlineLink
                                underline
                                url={{ pathname: routeMap.login, query: redirectParams }}
                                data-cy="bookmark-notLoggedInModal-login-button"
                            >
                                Log in
                            </InlineLink>
                        </Content>
                    </Box>
                </ModalContent>
            </Box>
        </Modal>
    )
}

const ModalContent = styled(Card)`
    padding: 1.5rem 1rem;
    width: 100%;
    max-width: 40rem;
    margin-bottom: 0;
`

const Content = styled(Set)`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 30rem;
    text-align: center;
    padding-bottom: 3rem;
`

const CloseIcon = styled(Icon)`
    margin-right: 1rem;
    float: right;
    cursor: pointer;
`

const InlineLink = styled(Link)`
    display: inline;
    color: inherit;
    font-weight: ${fonts.weight.normal};
`

export default NotLoggedInModal
